// Libs
import React from 'react';
// Deps
import ArchiveCard from './archive-card';
// State
import { experience } from '../../locale/locale.json';
// Assets
import styles from './experience-issues-archive.module.scss';

const ExperienceIssuesArchive = ({ data }) => {
  return (
    <>
      <h2 className={styles.experienceIssuesArchiveTitle}>{experience.archiveTitle}</h2>
      <div className={styles.experienceIssuesArchiveCardContainer}>
        {data.map((issue, index) => (
          <ArchiveCard data={issue} key={index}/>
        ))}
      </div>
    </>
  );
};

export default ExperienceIssuesArchive;
