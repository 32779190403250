import React from 'react';
import Logo from '../../images/longos-logo.svg';
import Img from 'gatsby-image';
import styles from './experience-hero.module.scss';

const ExperienceHero = ({ data }) => {
  return (
    <div className={styles.experienceHeroContainer}>
      <div className={styles.experienceHeroImage}>
       {data.image && data.image.fluid && <Img fluid={data.image.fluid} />}
       </div>
      <div className={styles.experienceHeroTitleContainer}>
      <img className={styles.experienceHeroLogo} src={Logo} alt="Longos" />
       <div className={styles.experienceHeroTitle}>{data.title}</div> 
      </div>
    </div>
  );
};

export default ExperienceHero;
