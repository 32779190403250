import React from 'react';

import { cloneDeep } from 'lodash';

import GatewayComponent from '../common/gateway-component/gateway-component';

const ArchiveCard = ({ data }) => {
  const cardData = cloneDeep(data);
  return <GatewayComponent data={cardData} />;
};

export default ArchiveCard;
