import React from 'react';

import ImageFeatureArea from '../common/image-feature-area/image-feature-area';
const ExperienceFeaturedIssue = ({ data }) => {
  return (
    <>
      {data && data.image && <ImageFeatureArea data={data} imageFluid={data.image} />}
    </>
  );
};
export default ExperienceFeaturedIssue;
