import React from 'react';
import Layout from '../components/layout/layout';
import SEO from '../components/layout/seo';
import ExperienceContainer from '../components/experience/experience-container';
import { graphql } from 'gatsby';

const EarnRewards = ({ data }) => (
  <Layout>
    <SEO title="Experience Magazine" />
    <ExperienceContainer data={data} />
  </Layout>
);

export default EarnRewards;

export const query = graphql`
query {
  hero: configPagesExpMag {
    title: field_exp_hero_title {
      value
    }
    relationships {
      image: field_exp_hero_image {
        localFile {
          childImageSharp {
            fluid(maxHeight: 810, srcSetBreakpoints: [557, 810]) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
  featuredMagazine: configPagesExpMag {
    relationships {
      featuredMagazine: field_featured_mag {
        title: field_issue_title
        current_issue: field_current_issue
        banner_title: field_banner_title
        label: field_magazine_featured_title
        text: field_magazine_summary {
          value
        }
        link: field_magazine_link {
          title
          uri
        }
        relationships {
          image: field_magazine_featured_image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 570) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
  pastIssues: configPagesExpMag {
    relationships {
      pastIssues: field_past_issues_exp {
        title: field_issue_title
        current_issue: field_current_issue
        banner_title: field_banner_title
        label: field_magazine_featured_title
        text: field_magazine_summary {
          value
        }
        link: field_magazine_link {
          title
          uri
        }
        relationships {
          image: field_magazine_featured_image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 570) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
}`;
