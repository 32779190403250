import React from 'react';
import { defaults } from 'lodash';
// State
import { formatFieldsValue } from '../../libs/formatFromGraph';
// import HeroCta from '../homepage/hero-cta';
import ExperienceHero from './experience-hero';
import ExperienceFeaturedIssue from './experience-featured-issue';
import ExperienceIssuesArchive from './experience-issues-archive';

import styles from './experience-container.module.scss';

const ExperienceContainer = ({ data }) => {
  const queryData = defaults(data || {}, {
  hero: { image : {}},
  featuredMagazine: {featuredMagazine: {}},
  pastIssues: {pastIssues:[]}}
  );
  const pageData = formatFieldsValue(queryData);
  return (
    <div className={styles.experienceContainerMain}>
      <ExperienceHero data={pageData.hero}/>
      <div className={styles.experienceContainerBody}>
        <ExperienceFeaturedIssue data={pageData.featuredMagazine.featuredMagazine}/>
        <ExperienceIssuesArchive data={pageData.pastIssues.pastIssues}/>
      </div>
    </div>
  );
};

export default ExperienceContainer;
